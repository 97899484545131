import { Container } from '@mui/material'
import React from 'react'
import Controls from './Controls'

function GridImportButton(props) {
    const { setUserHaveToUploadGrid } = props
    return (
        <Container>
            <div
                style={{
                    marginTop: '20px',
                    padding: '10px 0px 10px 0px',
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    borderTop: '1px solid #c0c0c0',
                    borderBottom: '1px solid #c0c0c0'
                }}
            >
                <Controls.Button
                    text="Importation de quadrillage"
                    size="large"
                    onClick={() => {
                        setUserHaveToUploadGrid(true)
                    }}
                />
            </div>
        </Container>
    )
}

export default GridImportButton
