import {
    HiOutlineCog,
    // HiOutlineCube,
    // HiOutlineUsers,
    // HiOutlineDocumentText,
    HiOutlineQuestionMarkCircle
} from 'react-icons/hi'

// import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'

//icons
import { ReactComponent as AgroforestryIcon } from '../assets/icons/agroforestry.svg'
import { ReactComponent as ConfigIcon } from '../assets/icons/config.svg'
import { ReactComponent as DashboardIcon } from '../assets/icons/dashboard.svg'
import { ReactComponent as MangrovesIcon } from '../assets/icons/mangroves.svg'
import { ReactComponent as OrderIcon } from '../assets/icons/order.svg'

export const DASHBOARD_SIDEBAR_LINKS = [
    {
        key: 'dashboard',
        label: 'Tableau de bord',
        path: '/app/dashboard',
        icon: <DashboardIcon />,
        permissions: ['show']
    },
    {
        key: 'agroforestry',
        label: 'Reg Ag et RPF',
        path: '/app/agroforestry',
        icon: <AgroforestryIcon />,
        submenu: [
            {
                key: 'pepiniere',
                path: '/app/agroforestry/nursery/supply',
                //icon: <ManageAccountsOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Gestion des pépinières',
                permissions: ['show', 'add', 'edit', 'delete', 'save', 'export']
            },
            {
                key: 'preregistry',
                path: '/app/agroforestry/preregistry',
                //icon: <LockPersonOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Pré-registre',
                permissions: ['show', 'add', 'edit', 'delete', 'showpeasant']
            },
            /*  {
                key: 'peasant',
                path: '/app/agroforestry/peasants',
                //icon: <LockPersonOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Paysans partenaires',
                permissions: ['show', 'add', 'edit', 'delete', 'showpeasant']
            }, */
            {
                key: 'plantation',
                path: '/app/agroforestry/plantations',
                //icon: <LockPersonOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Gestion des plantations',
                permissions: ['show', 'add', 'edit', 'delete', 'permission']
            },

            {
                key: 'commande',
                path: '/app/agroforestry/order',
                //icon: <LockPersonOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Commandes clients',
                permissions: ['show', 'add', 'edit', 'delete', 'permission']
            }
        ]
    },
    {
        key: 'mangrove',
        label: 'Mangroves',
        path: '/app/mangrove',
        icon: <MangrovesIcon />,
        submenu: [
            {
                key: 'pepiniere',
                path: '/app/mangrove/nursery/plat',
                //icon: <ManageAccountsOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Gestion des pépinières',
                permissions: ['show', 'add', 'edit', 'delete', 'save', 'export']
            },
            {
                key: 'terrain',
                path: '/app/mangrove/land',
                //icon: <ManageAccountsOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Terrain à restaurer',
                permissions: ['show', 'add', 'edit', 'delete', 'save', 'export']
            },
            {
                key: 'plantation',
                path: '/app/mangrove/planting',
                //icon: <ManageAccountsOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Gestion des plantations',
                permissions: ['show', 'add', 'edit', 'delete', 'save', 'export']
            },
            {
                key: 'planteur',
                path: '/app/mangrove/planter',
                //icon: <ManageAccountsOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Informations planteurs',
                permissions: ['show', 'add', 'edit', 'delete', 'save', 'export']
            },
            {
                key: 'commande',
                path: '/app/mangrove/order',
                //icon: <ManageAccountsOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Commandes clients',
                permissions: ['show', 'add', 'edit', 'delete', 'save', 'export']
            }
        ]
    },
    {
        key: 'commercial',
        label: 'Gestion des commandes',
        path: '/app/commercial',
        icon: <OrderIcon />,
        submenu: [
            {
                key: 'client',
                path: '/app/commercial/customer',
                //icon: <ManageAccountsOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Clients',
                permissions: ['show', 'add', 'edit', 'delete', 'save', 'export']
            },
            {
                key: 'commande',
                path: '/app/commercial/order',
                //icon: <ManageAccountsOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Suivi commandes',
                permissions: ['show', 'add', 'edit', 'delete', 'save', 'export']
            }
        ]
    },
    {
        key: 'administration',
        label: 'Administration',
        path: '',
        icon: <ConfigIcon />,
        submenu: [
            {
                key: 'users',
                path: '/app/users',
                //icon: <ManageAccountsOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Liste des utilisateurs',
                permissions: ['show', 'add', 'edit', 'delete', 'save', 'export']
            },
            {
                key: 'roles',
                path: '/app/users/roles',
                //icon: <LockPersonOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Rôles et Permissions',
                permissions: ['show', 'add', 'edit', 'delete', 'permission']
            },
            {
                key: 'form',
                path: '/app/form',
                //icon: <FaRemoveFormat style={{ fontSize: '1.1rem' }} />,
                label: 'Gestion des formulaires',
                permissions: ['show', 'add', 'edit', 'delete', 'permission']
            },
            {
                key: 'datasets',
                path: '/app/datasets',
                //icon: <LockPersonOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Données de base',
                permissions: ['show', 'add', 'edit', 'delete']
            },

            {
                key: 'species',
                path: '/app/species/fauna',
                label: 'Faunes et Flores',
                permissions: ['show', 'add', 'edit', 'delete']
            },
            {
                key: 'site',
                path: '/app/sites',
                //icon: <LockPersonOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Régions et Villages',
                permissions: ['show', 'add', 'edit', 'delete', 'permission']
            },
            {
                key: 'configuration',
                path: '/app/configuration',
                //icon: <LockPersonOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Gestion de configuration',
                permissions: ['show', 'add', 'edit', 'delete', 'permission']
            },
            {
                key: 'calibration',
                path: '/app/calibration',
                //icon: <LockPersonOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Gestion des Etalonnages',
                permissions: ['show', 'add', 'edit', 'delete', 'permission']
            },
            {
                key: 'historics',
                path: '/app/historics',
                //icon: <LockPersonOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Historique',
                permissions: ['show', 'add', 'edit', 'delete', 'permission']
            },
            {
                key: 'importation',
                path: '/app/importation',
                //icon: <LockPersonOutlinedIcon style={{ fontSize: '1.1rem' }} />,
                label: 'Importation des données',
                permissions: ['show', 'add', 'edit', 'delete', 'permission']
            },
        ]
    },
    
]

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
    {
        key: 'settings',
        label: 'Settings',
        path: '/app/settings',
        icon: <HiOutlineCog />
    },
    {
        key: 'support',
        label: 'Help & Support',
        path: '/app/support',
        icon: <HiOutlineQuestionMarkCircle />
    }
]

export const PERMISSIONS_LABEL = {
    show: 'Affichage',
    add: 'Création',
    edit: 'Modification',
    delete: 'Suppression',
    save: 'Enregistrement',
    import: 'Importation',
    export: 'Exportation',
    permission: 'Permissions',
    showpeasant: 'Détail paysan'
}
