export const landModel = {
    label: 'Terrain à restaurer (Mangrove)',
    tableName: 'land',
    properties: [
        {
            label: 'Surface',
            id: 'surface',
            type: 'text',
            required: true
        },
        {
            label: 'Classe de mangrove',
            id: 'mangrove_class',
            type: 'text',
            required: true
        },
        {
            label: 'Site',
            id: 'village',
            isForeignKey: true,
            column_name: 'name', // name of the column in the table associated to the foreign key
            table_column: 'site', // name of the column in the current table (here this is "land")
            type: 'text',
            required: true
        },
        {
            label: "polygone d'étude d'état initial",
            id: 'is_recipient_polygon',
            type: 'text',
            required: true
        },
        {
            label: 'Couleur substrat',
            id: 'substrat_color',
            type: 'text',
            required: true
        },
        {
            label: 'Profondeur',
            id: 'depth',
            type: 'text',
            required: true
        },
        {
            label: 'Texture de sol',
            id: 'soil_texture',
            type: 'text',
            required: true
        },
        {
            label: 'Salinité ‰',
            id: 'salinity',
            type: 'text',
            required: true
        },
        {
            label: 'Temps de submersion (h)',
            id: 'submers_time',
            type: 'text',
            required: true
        },
        {
            label: 'nombre de jours de submersion par mois ',
            id: 'mare_frequency',
            type: 'text',
            required: true
        },
        {
            label: 'Régénération naturelle : abondance',
            id: 'abundance',
            type: 'text',
            required: true
        },
        {
            label: 'Régénération naturelle : répartition',
            id: 'repartition',
            type: 'text',
            required: true
        },
        {
            label: 'Régénération naturelle : âge',
            id: 'age',
            type: 'text',
            required: true
        },
        {
            label: 'Cause de dégradation',
            id: 'cause',
            type: 'text',
            required: true
        },
        {
            label: 'Text explicatif de dégradation',
            id: 'explanation_cause',
            type: 'text',
            required: true
        },
        {
            label: 'Année de dégradation',
            id: 'degradation_date',
            type: 'text',
            required: true
        },
        {
            label: 'Présence des maladies',
            id: 'have_desease',
            type: 'text',
            required: true
        },
        {
            label: 'Changement d\'utilisation de terre',
            id: 'use_land_changement',
            type: 'text',
            required: true
        },
        {
            label: 'Texte explicatif de changement d\'utilisation de terre',
            id: 'explanation_use',
            type: 'text',
            required: true
        },
        {
            label: 'Note',
            id: 'note',
            type: 'text',
            required: true
        },
    ]
}
