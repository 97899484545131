import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@mui/styles'
import Controls from './Controls'

const containerStyle = {
    width: '100%',
    height: '300px'
}
const greenColor = '#14b67d'

const useStyles = makeStyles({
    exportButton: {
        backgroundColor: 'white',
        borderColor: greenColor,
        color: greenColor,
        '&:hover': {
            backgroundColor: greenColor,
            color: 'white'
        },
    }
})

const { ExportButton } = Controls

export default function Map({ data, zoom }) {
    const mapRef = useRef(null)
    const classes = useStyles()

    useEffect(() => {
        if (data && data.length > 0 && data[0].coordinates && window.google && window.google.maps) {
            const map = new window.google.maps.Map(mapRef.current, {
                center: { lat: data[0].coordinates[0].lat, lng: data[0].coordinates[0].lng },
                zoom: zoom
            })

            data.forEach((item) => {
                if (item.coordinates) {
                    const coordinates = item.coordinates.map((coordinate) => ({
                        lat: coordinate.lat,
                        lng: coordinate.lng
                    }))

                    const polyline = new window.google.maps.Polyline({
                        path: coordinates,
                        geodesic: true,
                        strokeColor: 'white',
                        strokeOpacity: 0.7,
                        strokeWeight: 4
                    })
                    polyline.setMap(map)

                    const polygon = new window.google.maps.Polygon({
                        paths: coordinates,
                        strokeColor: 'white',
                        strokeOpacity: 0.7,
                        strokeWeight: 4,
                        fillColor: 'green',
                        fillOpacity: 0.35
                    })
                    polygon.setMap(map)
                }
            })
        }
    }, [data, zoom])

    const exportGPX = () => {
        let gpxData = '<?xml version="1.0" encoding="UTF-8"?>\n'
        gpxData += '<gpx xmlns="http://www.topografix.com/GPX/1/1" version="1.1" creator="YourName">\n'

        data.forEach((item, index) => {
            gpxData += `<trk><name>Track ${index + 1}</name><trkseg>\n`
            item.coordinates.forEach((coordinate) => {
                gpxData += `<trkpt lat="${coordinate.lat}" lon="${coordinate.lng}"></trkpt>\n`
            })
            const firstCoordinate = item.coordinates[0]
            gpxData += `<trkpt lat="${firstCoordinate.lat}" lon="${firstCoordinate.lng}"></trkpt>\n`
            gpxData += '</trkseg></trk>\n'
        })

        gpxData += '</gpx>'

        const blob = new Blob([gpxData], { type: 'text/xml' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'export.gpx'
        link.click()
    }

    const exportKML = () => {
        let kmlData = '<?xml version="1.0" encoding="UTF-8"?>\n'
        kmlData += '<kml xmlns="http://www.opengis.net/kml/2.2">\n'

        data.forEach((item, index) => {
            kmlData += `<Placemark>\n`
            kmlData += `<name>Track ${index + 1}</name>\n`
            kmlData += `<LineString>\n<coordinates>\n`
            item.coordinates.forEach((coordinate) => {
                kmlData += `${coordinate.lng},${coordinate.lat} `
            })
            const firstCoordinate = item.coordinates[0]
            kmlData += `${firstCoordinate.lng},${firstCoordinate.lat} `
            kmlData += `</coordinates>\n</LineString>\n`
            kmlData += `</Placemark>\n`
        })

        kmlData += '</kml>'

        const blob = new Blob([kmlData], { type: 'text/xml' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'export.kml'
        link.click()
    }

    return (
        <div className="mt-[10px]">
            <div ref={mapRef} style={containerStyle}></div>
            <div className="flex justify-end gap-1 mt-[10px]">
                <ExportButton className={classes.exportButton} title="Exporter KML" action={exportKML} />
                <ExportButton className={classes.exportButton} title="Exporter GPX" action={exportGPX} />
            </div>
        </div>
    )
}
