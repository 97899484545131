/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { MdDragIndicator } from 'react-icons/md'
import { Box, Checkbox, Container, FormControl, MenuItem, Select, TextField } from '@mui/material'
import { RxTextAlignLeft } from 'react-icons/rx'
import { PiTextAlignCenterDuotone } from 'react-icons/pi'
import { GrSafariOption } from 'react-icons/gr'
import { IoIosCheckboxOutline } from 'react-icons/io'
// import { IoIosArrowDropdown } from 'react-icons/io'
import { IoMdCalendar } from 'react-icons/io'
import { IoSaveOutline } from 'react-icons/io5'
import { CiSquarePlus } from 'react-icons/ci'
import { FaTrashAlt } from 'react-icons/fa'
import { MdOutlineAddAPhoto } from 'react-icons/md'
import { CiCirclePlus } from 'react-icons/ci'
import { RxRadiobutton } from 'react-icons/rx'
import FormService from '../services/Form'
import { FiDatabase, FiMapPin } from 'react-icons/fi'
import { FaDatabase } from 'react-icons/fa6'
import DataSetsService from '../../datasets/services/DataSets'
import Controls from '../../../components/Entry/Controls'
import { FaPhone, FaEnvelope } from 'react-icons/fa'

export const CustomForm = (props) => {
    const { formId, toggleDrawer } = props

    const data = [
        { id: 1, label: 'Réponse courte', icon: <RxTextAlignLeft /> },
        { id: 2, label: 'Paragraphe', icon: <PiTextAlignCenterDuotone /> },
        { id: 4, label: 'Choix multiples', icon: <IoIosCheckboxOutline /> },
        { id: 3, label: 'Choix unique', icon: <GrSafariOption /> },
        // { id: 5, label: 'Liste déroulante', icon: <IoIosArrowDropdown /> },
        { id: 6, label: 'Date', icon: <IoMdCalendar /> },
        { id: 7, label: 'Map', icon: <FiMapPin /> },
        { id: 8, label: 'Données de base', icon: <FaDatabase /> },
        { id: 9, label: 'Collections', icon: <FaDatabase /> },
        { id: 10, label: 'Location', icon: <FiMapPin /> },
        { id: 11, label: 'Photo', icon: <MdOutlineAddAPhoto /> },
        { id: 12, label: 'Date limitée', icon: <IoMdCalendar /> },
        { id: 13, label: 'Téléphone', icon: <FaPhone /> },
        { id: 14, label: 'E-mail', icon: <FaEnvelope /> },
        { id: 15, label: 'Nombre', icon: <RxTextAlignLeft /> },
        { id: 16, label: 'Nombre décimal', icon: <RxTextAlignLeft /> },
        { id: 17, label: 'Insertion multiple', icon: <PiTextAlignCenterDuotone /> },
        { id: 18, label: 'Données de base (multiple)', icon: <FaDatabase /> },
        { id: 19, label: 'Sous données collection', icon: <FiDatabase /> }
    ]

    const dataInsertMultiple = [
        { id: 1, label: 'Réponse courte', icon: <RxTextAlignLeft /> },
        { id: 2, label: 'Paragraphe', icon: <PiTextAlignCenterDuotone /> },
        { id: 6, label: 'Date', icon: <IoMdCalendar /> },
        { id: 7, label: 'Map', icon: <FiMapPin /> },
        { id: 8, label: 'Données de base', icon: <FaDatabase /> },
        { id: 9, label: 'Collections', icon: <FaDatabase /> },
        { id: 10, label: 'Location', icon: <FiMapPin /> },
        { id: 11, label: 'Photo', icon: <MdOutlineAddAPhoto /> },
        { id: 12, label: 'Date limitée', icon: <IoMdCalendar /> },
        { id: 13, label: 'Téléphone', icon: <FaPhone /> },
        { id: 14, label: 'E-mail', icon: <FaEnvelope /> },
        { id: 15, label: 'Nombre', icon: <RxTextAlignLeft /> },
        { id: 16, label: 'Nombre décimal', icon: <RxTextAlignLeft /> },
        { id: 17, label: 'Insertion multiple', icon: <PiTextAlignCenterDuotone /> },
        { id: 18, label: 'Sous données collection', icon: <FiDatabase /> }
    ]

    const subDataInsertMultiple = [
        { id: 1, label: 'Réponse courte', icon: <RxTextAlignLeft /> },
        { id: 2, label: 'Paragraphe', icon: <PiTextAlignCenterDuotone /> },
        { id: 6, label: 'Date', icon: <IoMdCalendar /> },
        { id: 7, label: 'Map', icon: <FiMapPin /> },
        { id: 8, label: 'Données de base', icon: <FaDatabase /> },
        { id: 9, label: 'Collections', icon: <FaDatabase /> },
        { id: 10, label: 'Location', icon: <FiMapPin /> },
        { id: 11, label: 'Photo', icon: <MdOutlineAddAPhoto /> },
        { id: 12, label: 'Date limitée', icon: <IoMdCalendar /> },
        { id: 13, label: 'Téléphone', icon: <FaPhone /> },
        { id: 14, label: 'E-mail', icon: <FaEnvelope /> },
        { id: 15, label: 'Nombre', icon: <RxTextAlignLeft /> },
        { id: 16, label: 'Nombre décimal', icon: <RxTextAlignLeft /> },
        { id: 18, label: 'Sous données collection', icon: <FiDatabase /> }
    ]

    const [fields, setFields] = useState([
        { question: '', code: '', typeId: '', typeLabel: '', required: false, options: [{ label: '' }], optionCount: 1 }
    ])

    useEffect(() => {
        if (fields) {
            console.log(fields)
        }
    }, [fields])
    const [onLoad, setOnLoad] = useState(true)
    const [items, setItems] = useState()
    const collectionItems = [
        { id: 1, code: 'peasants', label: 'Agroforesterie - Paysans' },
        { id: 2, code: 'plots', label: 'Agroforesterie - Parcelles' },
        { id: 3, code: 'plantations', label: 'Agroforesterie - Plantations' },
        { id: 19, code: 'agro_nursery', label: 'Agroforesterie - Pépinière' },
        { id: 20, code: 'agro_nursery_semis', label: 'Agroforesterie - Sémis pépinière' },
        { id: 4, code: 'trainings', label: 'Formations' },
        { id: 5, code: 'species', label: 'Flores (multiples)' },
        { id: 6, code: 'flore_unique', label: 'Flore (unique)' },
        { id: 7, code: 'customers', label: 'Clients' },
        { id: 8, code: 'mangro_plat_drain', label: 'Mangrove - plat bande' },
        { id: 9, code: 'fauna', label: 'Faunes (multiples)' },
        { id: 10, code: 'fauna_unique', label: 'Faune (unique)' },
        { id: 11, code: 'orders', label: 'Commandes' },
        { id: 12, code: 'planter', label: 'Mangrove - planteurs' },
        { id: 13, code: 'mangro_plantations', label: 'Mangrove - plantations' },
        { id: 14, code: 'mangro_land', label: 'Mangrove - parcelles' },
        { id: 15, code: 'mangro_land_density', label: 'Mangrove - densité' },
        { id: 16, code: 'mangro_land_transect', label: 'Mangrove - transect' },
        { id: 17, code: 'users', label: 'Utilisateurs (unique)' },
        { id: 18, code: 'user_multiple', label: 'Utilisateurs (multiples)' },
        { id: 21, code: 'roles', label: "Fonction de l'utilisateur" },
        { id: 22, code: 'mangro_tracking_point', label: 'Point de suivi' }
    ]

    const locationItems = [
        { id: 1, code: 'agro_group', label: 'Agro groupe' },
        { id: 4, code: 'mangro_group', label: 'Mangrove groupe' },
        { id: 2, code: 'agro_location', label: 'Agro localisation' },
        { id: 3, code: 'mangro_location', label: 'Mangrove localisation' },
        { id: 5, code: 'mangro_group_multiple', label: 'Mangrove groupes multiple' }
    ]

    const photoItems = [
        { id: 1, code: 'simple_photo', label: 'Photo simple' },
        { id: 2, code: 'multiple_photo', label: 'Photo multiple' }
    ]

    useEffect(() => {
        if (onLoad) {
            FormService.getCustomForm(formId)
                .then((response) => {
                    return JSON.parse(response?.data?.form || '{}')
                })
                .then((data) => {
                    if (data?.data) {
                        setFields(data?.data)
                    }
                    setOnLoad(false)
                })
        }
    }, [fields])

    useEffect(() => {
        fetchData()
    }, [formId])

    const dataset = DataSetsService.load()

    const fetchData = () => {
        dataset
            .getAll()
            .then((response) => {
                if (response) {
                    setItems(response.data)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
    }

    const handleQuestionChange = (index, event) => {
        const { name, value } = event.target
        const newFields = [...fields]
        newFields[index][name] = value
        setFields(newFields)
    }

    const handleRequiredChange = (index, event) => {
        const { name, value } = event.target
        const newFields = [...fields]
        newFields[index][name] = value
        setFields(newFields)
    }

    const handleCodeChange = (index, event) => {
        const { name, value } = event.target
        const newFields = [...fields]
        newFields[index][name] = value
        setFields(newFields)
    }

    const handleTraductionChange = (index, event) => {
        const { name, value } = event.target
        const newFields = [...fields]
        newFields[index][name] = value
        setFields(newFields)
    }

    const handleInputChange = (fieldIndex, optionIndex, event) => {
        const { value } = event.target
        const newFields = [...fields]
        newFields[fieldIndex].options[optionIndex].label = value
        setFields(newFields)
    }
    const handleMultipleInsertChange = (fieldIndex, optionIndex, event) => {
        const { value, name } = event.target
        const newFields = [...fields]
        newFields[fieldIndex].options[optionIndex][name] = value
        console.log(newFields)
        setFields(newFields)
    }

    const handleTypeChange = (index, event) => {
        const { value } = event.target
        const selectedType = data.find((item) => item.id === parseInt(value, 10))
        const newFields = [...fields]
        newFields[index].typeId = value
        newFields[index].typeLabel = selectedType?.label
        setFields(newFields)
    }

    const handleTypeMultipleChange = (fieldIndex, optionIndex, event) => {
        const { value } = event.target
        const newFields = [...fields]
        const selectedType = dataInsertMultiple.find((item) => item.id === parseInt(value, 10))
        newFields[fieldIndex].options[optionIndex].typeId = value
        newFields[fieldIndex].options[optionIndex].typeLabel = selectedType.label
        console.log(newFields)
        setFields(newFields)
    }

    const handleSubTypeMultipleChange = (index, fieldIndex, optionIndex, event) => {
        const { value } = event.target
        const newFields = [...fields]
        const selectedType = subDataInsertMultiple.find((item) => item.id === parseInt(value, 10))
        newFields[index].options[fieldIndex].options[optionIndex].typeId = value
        newFields[index].options[fieldIndex].options[optionIndex].typeLabel = selectedType.label
        console.log(newFields)
        setFields(newFields)
    }

    const handleSubMultipleInsertChange = (index, fieldIndex, optionIndex, event) => {
        const { value, name } = event.target
        const newFields = [...fields]
        newFields[index].options[fieldIndex].options[optionIndex][name] = value
        console.log(newFields)
        setFields(newFields)
    }

    const handleDataSetMultipleChange = (fieldIndex, optionIndex, event) => {
        const { value } = event.target
        const selectedItem = items.find((item) => item?.id === value)
        const newFields = [...fields]
        newFields[fieldIndex].options[optionIndex].options = [
            { label: selectedItem?.title, value: `dataset/${selectedItem?.code}`, id: selectedItem?.id }
        ]
        console.log(newFields)
        setFields(newFields)
    }

    const handleSubDataSetMultipleChange = (index, fieldIndex, optionIndex, event) => {
        const { value } = event.target
        const selectedItem = items.find((item) => item?.id === value)
        const newFields = [...fields]
        newFields[index].options[fieldIndex].options[optionIndex].options = [
            { label: selectedItem?.title, value: `dataset/${selectedItem?.code}`, id: selectedItem?.id }
        ]
        console.log(newFields)
        setFields(newFields)
    }

    const handleCollectionMultipleChange = (fieldIndex, optionIndex, event) => {
        const { value } = event.target
        const selectedItem = collectionItems.find((item) => item?.id === value)
        const newFields = [...fields]
        newFields[fieldIndex].options[optionIndex].options = [
            { label: selectedItem?.label, value: `collection/${selectedItem?.code}`, id: selectedItem?.id }
        ]
        setFields(newFields)
    }

    const handleSubCollectionMultipleChange = (index, fieldIndex, optionIndex, event) => {
        const { value } = event.target
        const selectedItem = collectionItems.find((item) => item?.id === value)
        const newFields = [...fields]
        newFields[index].options[fieldIndex].options[optionIndex].options = [
            { label: selectedItem?.label, value: `collection/${selectedItem?.code}`, id: selectedItem?.id }
        ]
        console.log(newFields)
        setFields(newFields)
    }

    const handleDataSetChange = (fieldIndex, event) => {
        const { value } = event.target
        const selectedItem = items.find((item) => item?.id === value)
        const newFields = [...fields]
        newFields[fieldIndex].options = [
            { label: selectedItem?.title, value: `dataset/${selectedItem?.code}`, id: selectedItem?.id }
        ]
        console.log(newFields)
        setFields(newFields)
    }

    const handleCollectionChange = (fieldIndex, event) => {
        const { value } = event.target
        const selectedItem = collectionItems.find((item) => item?.id === value)
        console.log(selectedItem)
        const newFields = [...fields]
        newFields[fieldIndex].options = [
            { label: selectedItem?.label, value: `collection/${selectedItem?.code}`, id: selectedItem?.id }
        ]
        setFields(newFields)
    }

    const handleLocationChange = (fieldIndex, event) => {
        const { value } = event.target
        const selectedItem = locationItems.find((item) => item?.id === value)
        console.log(selectedItem)
        const newFields = [...fields]
        newFields[fieldIndex].options = [
            { label: selectedItem?.label, value: `location/${selectedItem?.code}`, id: selectedItem?.id }
        ]
        setFields(newFields)
    }

    const handleLocationMultipleChange = (index, fieldIndex, event) => {
        const { value } = event.target
        const selectedItem = locationItems.find((item) => item?.id === value)
        console.log(selectedItem)
        const newFields = [...fields]
        newFields[index].options[fieldIndex].options = [
            { label: selectedItem?.label, value: `location/${selectedItem?.code}`, id: selectedItem?.id }
        ]
        setFields(newFields)
    }

    const handlePhotoChange = (fieldIndex, event) => {
        const { value } = event.target
        const selectedItem = photoItems.find((item) => item?.id === value)
        console.log(selectedItem)
        const newFields = [...fields]
        newFields[fieldIndex].options = [
            { label: selectedItem?.label, value: `photo/${selectedItem?.code}`, id: selectedItem?.id }
        ]
        setFields(newFields)
    }

    const handleAddField = () => {
        setFields([
            ...fields,
            {
                question: '',
                code: '',
                typeId: '',
                typeLabel: '',
                required: false,
                options: [{ label: '' }],
                optionCount: 1
            }
        ])
    }

    const handleRemoveField = (indexToRemove) => {
        setFields(fields?.filter((_, index) => index !== indexToRemove))
    }

    const handleAddOption = (fieldIndex) => {
        const newFields = [...fields]
        const optionCount = newFields[fieldIndex].optionCount + 1
        newFields[fieldIndex].options.push({ label: '' })
        newFields[fieldIndex].optionCount = optionCount
        setFields(newFields)
    }

    const handleAddSubOption = (fieldIndex, subIndex) => {
        const newFields = [...fields]
        const optionCount = newFields[fieldIndex]?.options[subIndex]?.optionCount
            ? newFields[fieldIndex]?.options[subIndex]?.optionCount + 1
            : 1
        newFields[fieldIndex].options[subIndex].options
            ? newFields[fieldIndex].options[subIndex].options.push({ label: '' })
            : (newFields[fieldIndex].options[subIndex].options = [{ label: '' }])
        newFields[fieldIndex].options[subIndex].optionCount = optionCount
        setFields(newFields)
    }

    const deleteOption = (fieldIndex, optionIndex) => {
        const newFields = [...fields]
        const optionCount = newFields[fieldIndex].optionCount - 1
        newFields[fieldIndex].options.splice(optionIndex, 1)
        newFields[fieldIndex].optionCount = optionCount
        setFields(newFields)
    }

    const deleteSubOption = (fieldIndex, subIndex, optionIndex) => {
        const newFields = [...fields]
        const optionCount = newFields[fieldIndex]?.options[subIndex]?.optionCount - 1
        newFields[fieldIndex].options[subIndex].options.splice(optionIndex, 1)
        newFields[fieldIndex].options[subIndex].optionCount = optionCount
        setFields(newFields)
    }

    const handleSave = () => {
        console.log('json à envoyer', fields)
        const jsonData = JSON.stringify({ data: fields })
        console.log('jsonData', jsonData)
        FormService.setCustomForm(formId, { form: jsonData })
        toggleDrawer(false)
    }

    const onDragEnd = (result) => {
        if (!result.destination) return
        const items = [...fields]
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)
        setFields(items.map((item, index) => ({ ...item, id: String(index + 1) })))
    }

    return (
        <div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="fields">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {fields.map((field, index) => (
                                <Draggable key={index} draggableId={index.toString()} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="mb-[15px]  "
                                        >
                                            <div className="flex gap-1">
                                                <div className="mt-[10px]">
                                                    <MdDragIndicator className="w-5 h-5" />
                                                </div>
                                                <div className=" border border-gray-400 w-full ">
                                                    <div className="flex justify-between">
                                                        <div className="flex space-x-12">
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& > :not(style)': {
                                                                        m: 1,
                                                                        width: '25ch',
                                                                        height: '15px'
                                                                    }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <TextField
                                                                    value={field.question}
                                                                    onChange={(e) => handleQuestionChange(index, e)}
                                                                    id="standard-basic"
                                                                    label="Question"
                                                                    variant="standard"
                                                                    size="small"
                                                                    inputProps={{ style: { fontSize: '13px' } }}
                                                                    InputLabelProps={{ style: { fontSize: '13px' } }}
                                                                    name="question"
                                                                />
                                                            </Box>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& > :not(style)': {
                                                                        m: 1,
                                                                        width: '5ch',
                                                                        height: '15px'
                                                                    },
                                                                    '& .MuiFormControlLabel-label': {
                                                                        fontSize: '11px'
                                                                    }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <Controls.Checkbox
                                                                    value={field.required}
                                                                    onChange={(e) => handleRequiredChange(index, e)}
                                                                    id="standard-basic"
                                                                    variant="standard"
                                                                    label="Obligatoire"
                                                                    size="small"
                                                                    name="required"
                                                                />
                                                            </Box>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& > :not(style)': {
                                                                        m: 1,
                                                                        width: '10ch',
                                                                        height: '15px'
                                                                    },
                                                                    '& .MuiFormControlLabel-label': {
                                                                        fontSize: '11px'
                                                                    }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <TextField
                                                                    value={field.traduction}
                                                                    onChange={(e) => handleTraductionChange(index, e)}
                                                                    id="standard-basic"
                                                                    label="Traduction malagasy"
                                                                    variant="standard"
                                                                    size="small"
                                                                    inputProps={{ style: { fontSize: '13px' } }}
                                                                    InputLabelProps={{ style: { fontSize: '13px' } }}
                                                                    name="traduction"
                                                                />
                                                            </Box>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& > :not(style)': {
                                                                        m: 1,
                                                                        width: '12ch',
                                                                        height: '15px'
                                                                    }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <TextField
                                                                    value={field.code}
                                                                    onChange={(e) => handleCodeChange(index, e)}
                                                                    id="standard-basic"
                                                                    label="Colonne (Table)"
                                                                    variant="standard"
                                                                    size="small"
                                                                    inputProps={{ style: { fontSize: '13px' } }}
                                                                    InputLabelProps={{ style: { fontSize: '13px' } }}
                                                                    name="code"
                                                                />
                                                            </Box>
                                                            <Box sx={{ m: 1, mt: 2, minWidth: 120 }}>
                                                                <FormControl fullWidth>
                                                                    <Select
                                                                        value={field.typeId}
                                                                        onChange={(e) => handleTypeChange(index, e)}
                                                                        label="Type"
                                                                        size="small"
                                                                    >
                                                                        {data.map((item) => (
                                                                            <MenuItem key={item.id} value={item.id}>
                                                                                <div className="flex gap-3 text-xs">
                                                                                    <div className="mt-[2px]">
                                                                                        {item.icon}
                                                                                    </div>
                                                                                    <div>{item.label}</div>
                                                                                </div>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Box>
                                                            {field?.typeId === 8 || field?.typeId === 18 ? (
                                                                <Box sx={{ m: 1, mt: 2, minWidth: 120 }}>
                                                                    <FormControl fullWidth>
                                                                        <Select
                                                                            label="Type"
                                                                            size="small"
                                                                            value={field?.options[0]?.id || ''}
                                                                            onChange={(e) =>
                                                                                handleDataSetChange(index, e)
                                                                            }
                                                                        >
                                                                            {items?.map((i) => (
                                                                                <MenuItem key={i?.id} value={i?.id}>
                                                                                    <div className="flex gap-3 text-xs">
                                                                                        <div>
                                                                                            {i?.title
                                                                                                ? i?.title
                                                                                                : field?.options[0]
                                                                                                      ?.label}
                                                                                        </div>
                                                                                    </div>
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Box>
                                                            ) : null}
                                                            {field?.typeId === 9 && (
                                                                <Box sx={{ m: 1, mt: 2, minWidth: 120 }}>
                                                                    <FormControl fullWidth>
                                                                        <Select
                                                                            label="Type"
                                                                            size="small"
                                                                            value={field?.options[0]?.id || ''}
                                                                            onChange={(e) =>
                                                                                handleCollectionChange(index, e)
                                                                            }
                                                                        >
                                                                            {collectionItems?.map((i) => (
                                                                                <MenuItem key={i?.id} value={i?.id}>
                                                                                    <div className="flex gap-3 text-xs">
                                                                                        <div>{i?.label}</div>
                                                                                    </div>
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Box>
                                                            )}
                                                            {field?.typeId === 10 && (
                                                                <Box sx={{ m: 1, mt: 2, minWidth: 120 }}>
                                                                    <FormControl fullWidth>
                                                                        <Select
                                                                            label="Location"
                                                                            size="small"
                                                                            value={field?.options[0]?.id || ''}
                                                                            onChange={(e) =>
                                                                                handleLocationChange(index, e)
                                                                            }
                                                                        >
                                                                            {locationItems?.map((i) => (
                                                                                <MenuItem key={i?.id} value={i?.id}>
                                                                                    <div className="flex gap-3 text-xs">
                                                                                        <div>{i?.label}</div>
                                                                                    </div>
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Box>
                                                            )}
                                                            {field?.typeId === 11 && (
                                                                <Box sx={{ m: 1, mt: 2, minWidth: 120 }}>
                                                                    <FormControl fullWidth>
                                                                        <Select
                                                                            label="Location"
                                                                            size="small"
                                                                            value={field?.options[0]?.id || ''}
                                                                            onChange={(e) =>
                                                                                handlePhotoChange(index, e)
                                                                            }
                                                                        >
                                                                            {photoItems?.map((i) => (
                                                                                <MenuItem key={i?.id} value={i?.id}>
                                                                                    <div className="flex gap-3 text-xs">
                                                                                        <div>{i?.label}</div>
                                                                                    </div>
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Box>
                                                            )}
                                                            {field?.typeId === 19 && (
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        width: '100%'
                                                                    }}
                                                                >
                                                                    <TextField
                                                                        onChange={(e) => {
                                                                            console.log(e.target.value)
                                                                        }}
                                                                        id="standard-basic"
                                                                        label="Label"
                                                                        variant="standard"
                                                                        size="small"
                                                                        inputProps={{ style: { fontSize: '13px' } }}
                                                                        InputLabelProps={{
                                                                            style: { fontSize: '13px' }
                                                                        }}
                                                                        name="label"
                                                                    />
                                                                </Box>
                                                            )}
                                                        </div>
                                                        <div className="mt-[20px]">
                                                            {fields.length > 1 && (
                                                                <div className="flex justify-end items-end mr-2">
                                                                    <FaTrashAlt
                                                                        className="mb-[15px] mt-[5px] mr-[10px] w-5 h-5 transform hover:cursor-pointer text-gray-500  hover:scale-110"
                                                                        onClick={() => handleRemoveField(index)}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="w-full h-full mb-[5px]">
                                                        {/* <FieldInput type={field.typeId} handleChange={(e) => handleInputChange(index, e)} /> */}
                                                        {field.typeId === 3 && (
                                                            <>
                                                                <div>
                                                                    {field.options.map((item, optionIndex) => (
                                                                        <div
                                                                            className="flex gap-2 ml-[8px]"
                                                                            key={optionIndex}
                                                                        >
                                                                            <RxRadiobutton className="mt-[5px] w-5 h-5" />
                                                                            <input
                                                                                type="text"
                                                                                className="border border-gray-400  mb-[2px] p-[3px] text-sm"
                                                                                value={item.label}
                                                                                onChange={(e) =>
                                                                                    handleInputChange(
                                                                                        index,
                                                                                        optionIndex,
                                                                                        e
                                                                                    )
                                                                                }
                                                                            />
                                                                            {field.options.length > 1 && (
                                                                                <FaTrashAlt
                                                                                    className="text-gray-500 mt-[4px] hover:cursor-pointer transform hover:scale-110"
                                                                                    onClick={() =>
                                                                                        deleteOption(index, optionIndex)
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="hover:cursor-pointer ml-[30px] mt-[5px]">
                                                                    <CiCirclePlus
                                                                        onClick={() => handleAddOption(index)}
                                                                        className="w-8 h-8 "
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                        {field.typeId === 4 && (
                                                            <>
                                                                <div>
                                                                    {field.options.map((item, optionIndex) => (
                                                                        <div
                                                                            className="flex gap-2 ml-[8px]"
                                                                            key={optionIndex}
                                                                        >
                                                                            <Checkbox className="mt-[2px] w-5 h-5" />
                                                                            <input
                                                                                type="text"
                                                                                className="border border-gray-400 mb-[2px]  p-[3px] text-sm"
                                                                                value={item.label}
                                                                                onChange={(e) =>
                                                                                    handleInputChange(
                                                                                        index,
                                                                                        optionIndex,
                                                                                        e
                                                                                    )
                                                                                }
                                                                            />
                                                                            {field.options.length > 1 && (
                                                                                <FaTrashAlt
                                                                                    className="text-gray-500 mt-[4px] hover:cursor-pointer transform hover:scale-110"
                                                                                    onClick={() =>
                                                                                        deleteOption(index, optionIndex)
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="hover:cursor-pointer ml-[30px] mt-[5px]">
                                                                    <CiCirclePlus
                                                                        onClick={() => handleAddOption(index)}
                                                                        className="w-8 h-8 "
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                        {field.typeId === 5 && (
                                                            <>
                                                                <div>
                                                                    {field.options.map((item, optionIndex) => (
                                                                        <div
                                                                            className="flex gap-2 ml-[8px]"
                                                                            key={optionIndex}
                                                                        >
                                                                            <h1 className="mt-[3px] w-5 h-5">
                                                                                {optionIndex + 1}.
                                                                            </h1>
                                                                            <input
                                                                                type="text"
                                                                                className="border border-gray-400  mb-[2px]  p-[3px] text-sm"
                                                                                value={item.label}
                                                                                onChange={(e) =>
                                                                                    handleInputChange(
                                                                                        index,
                                                                                        optionIndex,
                                                                                        e
                                                                                    )
                                                                                }
                                                                            />
                                                                            {field.options.length > 1 && (
                                                                                <FaTrashAlt
                                                                                    className="text-gray-500 mt-[4px] hover:cursor-pointer transform hover:scale-110"
                                                                                    onClick={() =>
                                                                                        deleteOption(index, optionIndex)
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="hover:cursor-pointer ml-[30px] mt-[5px]">
                                                                    <CiCirclePlus
                                                                        onClick={() => handleAddOption(index)}
                                                                        className="w-8 h-8 "
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                        {field?.typeId === 17 && (
                                                            <>
                                                                <div>
                                                                    {field.options.map((item, optionIndex) => (
                                                                        <div
                                                                            className="flex gap-2 ml-[8px] mb-[10px]"
                                                                            key={optionIndex}
                                                                        >
                                                                            <Container>
                                                                                <div
                                                                                    className="flex gap-3"
                                                                                    style={{ alignItems: 'center' }}
                                                                                >
                                                                                    <TextField
                                                                                        value={item.label}
                                                                                        id="standard-basic"
                                                                                        label="Question"
                                                                                        variant="standard"
                                                                                        size="small"
                                                                                        onChange={(e) =>
                                                                                            handleMultipleInsertChange(
                                                                                                index,
                                                                                                optionIndex,
                                                                                                e
                                                                                            )
                                                                                        }
                                                                                        inputProps={{
                                                                                            style: { fontSize: '13px' }
                                                                                        }}
                                                                                        InputLabelProps={{
                                                                                            style: { fontSize: '13px' }
                                                                                        }}
                                                                                        name="label"
                                                                                    />
                                                                                    <TextField
                                                                                        value={item.column}
                                                                                        id="standard-basic"
                                                                                        label="Colonne"
                                                                                        variant="standard"
                                                                                        size="small"
                                                                                        onChange={(e) =>
                                                                                            handleMultipleInsertChange(
                                                                                                index,
                                                                                                optionIndex,
                                                                                                e
                                                                                            )
                                                                                        }
                                                                                        inputProps={{
                                                                                            style: { fontSize: '13px' }
                                                                                        }}
                                                                                        InputLabelProps={{
                                                                                            style: { fontSize: '13px' }
                                                                                        }}
                                                                                        name="column"
                                                                                    />
                                                                                    <Box
                                                                                        sx={{
                                                                                            m: 1,
                                                                                            mt: 2,
                                                                                            minWidth: 120
                                                                                        }}
                                                                                    >
                                                                                        <FormControl fullWidth>
                                                                                            <Select
                                                                                                value={item.typeId}
                                                                                                onChange={(e) => {
                                                                                                    handleTypeMultipleChange(
                                                                                                        index,
                                                                                                        optionIndex,
                                                                                                        e
                                                                                                    )
                                                                                                }}
                                                                                                label="Type"
                                                                                                size="small"
                                                                                            >
                                                                                                {dataInsertMultiple.map(
                                                                                                    (item) => (
                                                                                                        <MenuItem
                                                                                                            key={
                                                                                                                item.id
                                                                                                            }
                                                                                                            value={
                                                                                                                item.id
                                                                                                            }
                                                                                                        >
                                                                                                            <div className="flex gap-3 text-xs">
                                                                                                                <div className="mt-[2px]">
                                                                                                                    {
                                                                                                                        item.icon
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {
                                                                                                                        item.label
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </MenuItem>
                                                                                                    )
                                                                                                )}
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </Box>
                                                                                    {item?.typeId === 8 && (
                                                                                        <Box
                                                                                            sx={{
                                                                                                m: 1,
                                                                                                mt: 2,
                                                                                                minWidth: 120
                                                                                            }}
                                                                                        >
                                                                                            <FormControl fullWidth>
                                                                                                <Select
                                                                                                    label="Type"
                                                                                                    size="small"
                                                                                                    value={
                                                                                                        item?.options
                                                                                                            ? item
                                                                                                                  ?.options[0]
                                                                                                                  .id
                                                                                                            : ''
                                                                                                    }
                                                                                                    onChange={(e) =>
                                                                                                        handleDataSetMultipleChange(
                                                                                                            index,
                                                                                                            optionIndex,
                                                                                                            e
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {items?.map((i) => (
                                                                                                        <MenuItem
                                                                                                            key={i?.id}
                                                                                                            value={
                                                                                                                i?.id
                                                                                                            }
                                                                                                        >
                                                                                                            <div className="flex gap-3 text-xs">
                                                                                                                <div>
                                                                                                                    {i?.title
                                                                                                                        ? i?.title
                                                                                                                        : field
                                                                                                                              ?.options[0]
                                                                                                                              ?.label}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </Select>
                                                                                            </FormControl>
                                                                                        </Box>
                                                                                    )}

                                                                                    {item?.typeId === 10 && (
                                                                                        <Box
                                                                                            sx={{
                                                                                                m: 1,
                                                                                                mt: 2,
                                                                                                minWidth: 120
                                                                                            }}
                                                                                        >
                                                                                            <FormControl fullWidth>
                                                                                                <Select
                                                                                                    label="Location"
                                                                                                    size="small"
                                                                                                    value={
                                                                                                        item?.options
                                                                                                            ? item
                                                                                                                  ?.options[0]
                                                                                                                  .id
                                                                                                            : ''
                                                                                                    }
                                                                                                    onChange={(e) =>
                                                                                                        handleLocationMultipleChange(
                                                                                                            index,
                                                                                                            optionIndex,
                                                                                                            e
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {locationItems?.map(
                                                                                                        (i) => (
                                                                                                            <MenuItem
                                                                                                                key={
                                                                                                                    i?.id
                                                                                                                }
                                                                                                                value={
                                                                                                                    i?.id
                                                                                                                }
                                                                                                            >
                                                                                                                <div className="flex gap-3 text-xs">
                                                                                                                    <div>
                                                                                                                        {
                                                                                                                            i?.label
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </MenuItem>
                                                                                                        )
                                                                                                    )}
                                                                                                </Select>
                                                                                            </FormControl>
                                                                                        </Box>
                                                                                    )}

                                                                                    {item?.typeId === 17 && (
                                                                                        <>
                                                                                            <div>
                                                                                                {item?.options?.map(
                                                                                                    (
                                                                                                        item,
                                                                                                        subOptionIndex
                                                                                                    ) => (
                                                                                                        <div
                                                                                                            className="flex gap-2 ml-[8px] mb-[10px]"
                                                                                                            key={
                                                                                                                subOptionIndex
                                                                                                            }
                                                                                                        >
                                                                                                            <Container>
                                                                                                                <div
                                                                                                                    className="flex gap-3"
                                                                                                                    style={{
                                                                                                                        alignItems:
                                                                                                                            'center'
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <TextField
                                                                                                                        value={
                                                                                                                            item.label
                                                                                                                        }
                                                                                                                        id="standard-basic"
                                                                                                                        label="Question"
                                                                                                                        variant="standard"
                                                                                                                        size="small"
                                                                                                                        onChange={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            handleSubMultipleInsertChange(
                                                                                                                                index,
                                                                                                                                optionIndex,
                                                                                                                                subOptionIndex,
                                                                                                                                e
                                                                                                                            )
                                                                                                                        }
                                                                                                                        inputProps={{
                                                                                                                            style: {
                                                                                                                                fontSize:
                                                                                                                                    '13px'
                                                                                                                            }
                                                                                                                        }}
                                                                                                                        InputLabelProps={{
                                                                                                                            style: {
                                                                                                                                fontSize:
                                                                                                                                    '13px'
                                                                                                                            }
                                                                                                                        }}
                                                                                                                        name="label"
                                                                                                                    />
                                                                                                                    <TextField
                                                                                                                        value={
                                                                                                                            item.column
                                                                                                                        }
                                                                                                                        id="standard-basic"
                                                                                                                        label="Colonne"
                                                                                                                        variant="standard"
                                                                                                                        size="small"
                                                                                                                        onChange={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            handleSubMultipleInsertChange(
                                                                                                                                index,
                                                                                                                                optionIndex,
                                                                                                                                subOptionIndex,
                                                                                                                                e
                                                                                                                            )
                                                                                                                        }
                                                                                                                        inputProps={{
                                                                                                                            style: {
                                                                                                                                fontSize:
                                                                                                                                    '13px'
                                                                                                                            }
                                                                                                                        }}
                                                                                                                        InputLabelProps={{
                                                                                                                            style: {
                                                                                                                                fontSize:
                                                                                                                                    '13px'
                                                                                                                            }
                                                                                                                        }}
                                                                                                                        name="column"
                                                                                                                    />
                                                                                                                    <Box
                                                                                                                        sx={{
                                                                                                                            m: 1,
                                                                                                                            mt: 2,
                                                                                                                            minWidth: 120
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <FormControl
                                                                                                                            fullWidth
                                                                                                                        >
                                                                                                                            <Select
                                                                                                                                value={
                                                                                                                                    item.typeId
                                                                                                                                }
                                                                                                                                onChange={(
                                                                                                                                    e
                                                                                                                                ) => {
                                                                                                                                    handleSubTypeMultipleChange(
                                                                                                                                        index,
                                                                                                                                        optionIndex,
                                                                                                                                        subOptionIndex,
                                                                                                                                        e
                                                                                                                                    )
                                                                                                                                }}
                                                                                                                                label="Type"
                                                                                                                                size="small"
                                                                                                                            >
                                                                                                                                {subDataInsertMultiple.map(
                                                                                                                                    (
                                                                                                                                        item
                                                                                                                                    ) => (
                                                                                                                                        <MenuItem
                                                                                                                                            key={
                                                                                                                                                item.id
                                                                                                                                            }
                                                                                                                                            value={
                                                                                                                                                item.id
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            <div className="flex gap-3 text-xs">
                                                                                                                                                <div className="mt-[2px]">
                                                                                                                                                    {
                                                                                                                                                        item.icon
                                                                                                                                                    }
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    {
                                                                                                                                                        item.label
                                                                                                                                                    }
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </MenuItem>
                                                                                                                                    )
                                                                                                                                )}
                                                                                                                            </Select>
                                                                                                                        </FormControl>
                                                                                                                    </Box>
                                                                                                                    {item?.typeId ===
                                                                                                                        8 && (
                                                                                                                        <Box
                                                                                                                            sx={{
                                                                                                                                m: 1,
                                                                                                                                mt: 2,
                                                                                                                                minWidth: 120
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <FormControl
                                                                                                                                fullWidth
                                                                                                                            >
                                                                                                                                <Select
                                                                                                                                    label="Type"
                                                                                                                                    size="small"
                                                                                                                                    value={
                                                                                                                                        item?.options
                                                                                                                                            ? item
                                                                                                                                                  ?.options[0]
                                                                                                                                                  .id
                                                                                                                                            : ''
                                                                                                                                    }
                                                                                                                                    onChange={(
                                                                                                                                        e
                                                                                                                                    ) =>
                                                                                                                                        handleSubDataSetMultipleChange(
                                                                                                                                            index,
                                                                                                                                            optionIndex,
                                                                                                                                            subOptionIndex,
                                                                                                                                            e
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    {items?.map(
                                                                                                                                        (
                                                                                                                                            i
                                                                                                                                        ) => (
                                                                                                                                            <MenuItem
                                                                                                                                                key={
                                                                                                                                                    i?.id
                                                                                                                                                }
                                                                                                                                                value={
                                                                                                                                                    i?.id
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                <div className="flex gap-3 text-xs">
                                                                                                                                                    <div>
                                                                                                                                                        {i?.title
                                                                                                                                                            ? i?.title
                                                                                                                                                            : field
                                                                                                                                                                  ?.options[0]
                                                                                                                                                                  ?.label}
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </MenuItem>
                                                                                                                                        )
                                                                                                                                    )}
                                                                                                                                </Select>
                                                                                                                            </FormControl>
                                                                                                                        </Box>
                                                                                                                    )}

                                                                                                                    {item?.typeId ===
                                                                                                                        9 && (
                                                                                                                        <Box
                                                                                                                            sx={{
                                                                                                                                m: 1,
                                                                                                                                mt: 2,
                                                                                                                                minWidth: 120
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <FormControl
                                                                                                                                fullWidth
                                                                                                                            >
                                                                                                                                <Select
                                                                                                                                    label="Type"
                                                                                                                                    size="small"
                                                                                                                                    value={
                                                                                                                                        item?.options
                                                                                                                                            ? item
                                                                                                                                                  ?.options[0]
                                                                                                                                                  ?.id
                                                                                                                                            : ''
                                                                                                                                    }
                                                                                                                                    onChange={(
                                                                                                                                        e
                                                                                                                                    ) =>
                                                                                                                                        handleSubCollectionMultipleChange(
                                                                                                                                            index,
                                                                                                                                            optionIndex,
                                                                                                                                            subOptionIndex,
                                                                                                                                            e
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    {collectionItems?.map(
                                                                                                                                        (
                                                                                                                                            i
                                                                                                                                        ) => (
                                                                                                                                            <MenuItem
                                                                                                                                                key={
                                                                                                                                                    i?.id
                                                                                                                                                }
                                                                                                                                                value={
                                                                                                                                                    i?.id
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                <div className="flex gap-3 text-xs">
                                                                                                                                                    <div>
                                                                                                                                                        {
                                                                                                                                                            i?.label
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </MenuItem>
                                                                                                                                        )
                                                                                                                                    )}
                                                                                                                                </Select>
                                                                                                                            </FormControl>
                                                                                                                        </Box>
                                                                                                                    )}

                                                                                                                    {field
                                                                                                                        .options
                                                                                                                        .length >
                                                                                                                        1 && (
                                                                                                                        <FaTrashAlt
                                                                                                                            className="text-gray-500 mt-[4px] hover:cursor-pointer transform hover:scale-110"
                                                                                                                            onClick={() =>
                                                                                                                                deleteSubOption(
                                                                                                                                    index,
                                                                                                                                    optionIndex,
                                                                                                                                    subOptionIndex
                                                                                                                                )
                                                                                                                            }
                                                                                                                        />
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </Container>
                                                                                                        </div>
                                                                                                    )
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="hover:cursor-pointer ml-[30px] mt-[5px]">
                                                                                                <CiCirclePlus
                                                                                                    onClick={() =>
                                                                                                        handleAddSubOption(
                                                                                                            index,
                                                                                                            optionIndex
                                                                                                        )
                                                                                                    }
                                                                                                    className="w-8 h-8 "
                                                                                                />
                                                                                            </div>
                                                                                        </>
                                                                                    )}

                                                                                    {item?.typeId === 9 && (
                                                                                        <Box
                                                                                            sx={{
                                                                                                m: 1,
                                                                                                mt: 2,
                                                                                                minWidth: 120
                                                                                            }}
                                                                                        >
                                                                                            <FormControl fullWidth>
                                                                                                <Select
                                                                                                    label="Type"
                                                                                                    size="small"
                                                                                                    value={
                                                                                                        item?.options
                                                                                                            ? item
                                                                                                                  ?.options[0]
                                                                                                                  ?.id
                                                                                                            : ''
                                                                                                    }
                                                                                                    onChange={(e) =>
                                                                                                        handleCollectionMultipleChange(
                                                                                                            index,
                                                                                                            optionIndex,
                                                                                                            e
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {collectionItems?.map(
                                                                                                        (i) => (
                                                                                                            <MenuItem
                                                                                                                key={
                                                                                                                    i?.id
                                                                                                                }
                                                                                                                value={
                                                                                                                    i?.id
                                                                                                                }
                                                                                                            >
                                                                                                                <div className="flex gap-3 text-xs">
                                                                                                                    <div>
                                                                                                                        {
                                                                                                                            i?.label
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </MenuItem>
                                                                                                        )
                                                                                                    )}
                                                                                                </Select>
                                                                                            </FormControl>
                                                                                        </Box>
                                                                                    )}

                                                                                    {field.options.length > 1 && (
                                                                                        <FaTrashAlt
                                                                                            className="text-gray-500 mt-[4px] hover:cursor-pointer transform hover:scale-110"
                                                                                            onClick={() =>
                                                                                                deleteOption(
                                                                                                    index,
                                                                                                    optionIndex
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </Container>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="hover:cursor-pointer ml-[30px] mt-[5px]">
                                                                    <CiCirclePlus
                                                                        onClick={() => handleAddOption(index)}
                                                                        className="w-8 h-8 "
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="flex gap-1 justify-end mt-[10px]">
                <CiSquarePlus
                    onClick={handleAddField}
                    className="w-11 h-11 transform hover:cursor-pointer text-gray-500  hover:scale-110"
                    title="Ajouter"
                />
                <IoSaveOutline
                    className="w-10 h-10 mt-[2px] transform hover:cursor-pointer text-gray-500  hover:scale-110"
                    title="Enregistrer"
                    onClick={handleSave}
                />
            </div>
        </div>
    )
}
